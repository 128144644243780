import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation, createBrowserRouter, RouterProvider} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import log from 'loglevel';

//import NotFoundPage from "./NotFoundPage";
//import LoginPage from "./LoginPage";
//import ForgotPasswordPage from "./ForgotPasswordPage";
//import ResetPasswordPage from "./ResetPasswordPage";
//import ChangePasswordPage from "./ChangePasswordPage";
//import TestAPIPage from "./TestAPIPage";
//import AccountPage from "./AccountPage";

//import PrivateBrowserRoute from "../components/PrivateBrowserRoute";
//import MainPage from "./MainPage";
//import PrivateRoutes from "../components/PrivateRoutes";

import Loader from "../components/Loader";
import ErrorBoundary from "../components/ErrorBoundary";

import { ProvideAuth} from "../services/use-auth";
import { ProvideConfig } from "../services/use-config";

//import UserConfirmation from "../components/UserConfirmation";

//import Alert from 'react-bootstrap/Alert';
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';
//import { Trans } from 'react-i18next';

//import Users from "../components/Users";

const NotFoundPage = lazy(() => import("./NotFoundPage"));
const LoginPage = lazy(() => import("./LoginPage"));
const ForgotPasswordPage = lazy(() => import("./ForgotPasswordPage"));
const ResetPasswordPage = lazy(() => import("./ResetPasswordPage"));
const ChangePasswordPage = lazy(() => import("./ChangePasswordPage"));

const MainPage = lazy(() => import("./MainPage"));
const PrivateBrowserRoute = lazy(() => import("../components/PrivateBrowserRoute"));

const AccountPage = lazy(() => import("./AccountPage"));


const useQuery = () => { 
	  return new URLSearchParams(useLocation().search);
};

/*const LogLevel = props => {
	
	let query = useQuery();
	
	//log.setLevel("error");
	
	//if (query.get("loglevel"))
	//  	log.setLevel(query.get("loglevel"));
	
	//TEMPORARY
	log.enableAll();
	
	return null;
}*/


const AppRoutes = props => {

	let privateRoutes = PrivateRoutesConfig.map((route, index) => {
		    const { 
		    	path, 
		    	component: Component, 
		    	title,
		    	roles,
		    	permissions,
				hierarchy,
		    	...rest 
		    } = route;
								
		    return (
				<Route key={index} path={path} element={<Component />} />
		    )
	})
	
	return (
		<Routes>
			<Route exact path="/login" element={<LoginPage/>} />
	     	<Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
	     	<Route exact path="/reset-password/:token" element={<ResetPasswordPage />}  />
	     	{/*<Route exact path="/test-api" element={<TestAPIPage />}  />*/}
			<Route exact path="/account" element={<AccountPage />} />
					
			<Route exact path="/change-password" element={<ChangePasswordPage />} />
			
	     	<Route path="/" element={<PrivateBrowserRoute/>}>
				<Route element={<MainPage/>}>
					{privateRoutes}
				</Route>
			</Route>
	     	
	     	<Route path={"*"} element={<NotFoundPage />} />
	     </Routes>
	);
}

const router = createBrowserRouter([
  	{ path: "*", Component: AppRoutes },
]);

const Root = props => {

	const { i18n } = useTranslation();
	
	return (
		<ProvideConfig>	
		  <ProvideAuth>	
	     	<RouterProvider router={router} />
		 </ProvideAuth>
	   </ProvideConfig>	
	  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
    	<ErrorBoundary>
    		<Root />
    	</ErrorBoundary>
    </Suspense>
  );
}
