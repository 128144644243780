import { lazy } from 'react';
import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

//import Loader from "../components/Loader";
//import Users from "../components/Users";
//import SaveUser from "../components/SaveUser";
//import UpdateUserPassword from "../components/UpdateUserPassword";
//import UpdateSelfPassword from "../components/UpdateSelfPassword";
//import Home from "../components/Home";
//import Activities from "../components/Activities";
//import Accounts from "../components/Accounts";
//import SaveAccount from "../components/SaveAccount";
//import AccountsImport from "../components/AccountsImport";
//import Products from "../components/Products";
//import SaveProduct from "../components/SaveProduct";
//import Orders from "../components/Orders";
//import OrderSheets from "../components/OrderSheets";
//import ManageOrderSheets from "../components/ManageOrderSheets";
//import AccountTransactions from "../components/AccountTransactions";
//import AccountStatements from "../components/AccountStatements";
//import OrdersSummaryReport from "../components/OrdersSummaryReport";
//import CustomTags from "../components/CustomTags";
//import ProductSuppliers from "../components/ProductSuppliers";
//import SupplierOrders from '../components/SupplierOrders';
//import SaveSupplierOrder from '../components/SaveSupplierOrder';
//import LedgerTransactions from "../components/LedgerTransactions";
//import InventoryCounts from '../components/InventoryCounts';
//import SaveInventoryCount from '../components/SaveInventoryCount';
//import LedgerTransactionsImport from '../components/LedgerTransactionsImport';
//import InventoryCountsSummaryReport from '../components/InventoryCountsSummaryReport';
//import AccountTransactionsImport from '../components/AccountTransactionsImport';
//import AccountingSummaryReport from '../components/AccountingSummaryReport';
//import Ledgers from '../components/Ledgers';
//import SaveLedger from '../components/SaveLedger';
//import Messages from '../components/Messages';
//import AccountActivities from '../components/AccountActivities';
//import MediaFiles from '../components/MediaFiles';
//import Suppliers from '../components/Suppliers';
//import SaveSupplier from '../components/SaveSupplier';


const Users = lazy(() => import('../components/Users'));
const SaveUser = lazy(() => import('../components/SaveUser'));
const UpdateUserPassword = lazy(() => import('../components/UpdateUserPassword'));
const UpdateSelfPassword = lazy(() => import('../components/UpdateSelfPassword'));
const Home = lazy(() => import('../components/Home'));
const About = lazy(() => import('../components/About'));
const Activities = lazy(() => import('../components/Activities'));

const Accounts = lazy(() => import('../components/Accounts'));
const SaveAccount = lazy(() => import('../components/SaveAccount'));
const AccountsImport = lazy(() => import('../components/AccountsImport'));
const Products = lazy(() => import('../components/Products'));
const SaveProduct = lazy(() => import('../components/SaveProduct'));
const Orders = lazy(() => import('../components/Orders'));
const OrderSheets = lazy(() => import('../components/OrderSheets'));
const ManageOrderSheets = lazy(() => import('../components/ManageOrderSheets'));
const AccountTransactions = lazy(() => import('../components/AccountTransactions'));
const AccountStatements = lazy(() => import('../components/AccountStatements'));
const OrdersSummaryReport = lazy(() => import('../components/OrdersSummaryReport'));
const CustomTags = lazy(() => import('../components/CustomTags'));
const ProductSuppliers = lazy(() => import('../components/ProductSuppliers'));
const SupplierOrders = lazy(() => import('../components/SupplierOrders'));
const SaveSupplierOrder = lazy(() => import('../components/SaveSupplierOrder'));
const LedgerTransactions = lazy(() => import('../components/LedgerTransactions'));
const InventoryCounts = lazy(() => import('../components/InventoryCounts'));
const SaveInventoryCount = lazy(() => import('../components/SaveInventoryCount'));
const LedgerTransactionsImport = lazy(() => import('../components/LedgerTransactionsImport'));
const InventoryCountsSummaryReport = lazy(() => import('../components/InventoryCountsSummaryReport'));
const AccountTransactionsImport = lazy(() => import('../components/AccountTransactionsImport'));
const AccountingSummaryReport = lazy(() => import('../components/AccountingSummaryReport'));
const Ledgers = lazy(() => import('../components/Ledgers'));
const SaveLedger = lazy(() => import('../components/SaveLedger'));
const Messages = lazy(() => import('../components/Messages'));
const AccountActivities = lazy(() => import('../components/AccountActivities'));
const MediaFiles = lazy(() => import('../components/MediaFiles'));
const Suppliers = lazy(() => import('../components/Suppliers'));
const SaveSupplier = lazy(() => import('../components/SaveSupplier'));





export const PrivateRoutesConfig = [
	{
		component: Users,
		path: '/users',
		title: 'header.navigation-titles.users',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		],
		home: [
			Roles.ROOT
		],
	},
	{
		component: SaveUser,
		path: '/users/create',
		title: 'header.navigation-titles.users-create',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: SaveUser,
		path: '/users/update',
		title: 'header.navigation-titles.users-update',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: UpdateUserPassword,
		path: '/users/update-password',
		title: 'header.navigation-titles.users-password-update',
		exact: true,
		roles: [
			Roles.ROOT
		]
	},
	{
		component: UpdateSelfPassword,
		path: '/profile/change-password',
		title: 'header.navigation-titles.profile-change-password',
		exact: true,
		hierarchy: [
			{
				title: "header.navigation-titles.profile"
			}
		]
	},
	{
		component: Home,
		path: '/home',
		title: 'header.navigation-titles.home',
		exact: true
	},
	{
		component: Ledgers,
		path: '/ledgers',
		title: 'header.navigation-titles.ledgers',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		],
		home: [
			Roles.ROOT
		],
	},
	{
		component: SaveLedger,
		path: '/ledgers/create',
		title: 'header.navigation-titles.ledgers-create',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.ledgers",
				path : "/ledgers"
			},
		]
	},
	{
		component: SaveLedger,
		path: '/ledgers/update',
		title: 'header.navigation-titles.ledgers-update',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			},
			{
				title: "header.navigation-titles.ledgers",
				path : "/ledgers"
			},
		]
	},
	{
		component: Activities,
		path: '/activities',
		title:  'header.navigation-titles.activities',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: AccountsImport,
		path: '/accounts/import',
		title: 'header.navigation-titles.accounts-import',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: LedgerTransactionsImport,
		path: '/ledger-transactions/import',
		title: 'header.navigation-titles.ledger-transactions-import',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: AccountTransactionsImport,
		path: '/account-transactions/import',
		title: 'header.navigation-titles.account-transactions-import',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: About,
		path: '/about',
		title:  'header.navigation-titles.about',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.support"
			}
		]
	},
	{
		component: Products,
		path: '/products',
		title: 'header.navigation-titles.products',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveProduct,
		path: '/products/create',
		title: 'header.navigation-titles.products-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.products",
				path : "/products"
			},
		]
	},
	{
		component: SaveProduct,
		path: '/products/update',
		title: 'header.navigation-titles.products-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.products",
				path : "/products"
			},
		]
	},
	{
		component: Suppliers,
		path: '/suppliers',
		title: 'header.navigation-titles.suppliers',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveSupplier,
		path: '/suppliers/create',
		title: 'header.navigation-titles.suppliers-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.suppliers",
				path : "/suppliers"
			},
		]
	},
	{
		component: SaveSupplier,
		path: '/suppliers/update',
		title: 'header.navigation-titles.suppliers-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.suppliers",
				path : "/suppliers"
			},
		]
	},
	{
		component: ProductSuppliers,
		path: '/products/suppliers',
		title: 'header.navigation-titles.product-suppliers',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.products",
				path : "/products"
			},
		]
	},
	{
		component: SupplierOrders,
		path: '/supplier-orders',
		title: 'header.navigation-titles.supplier-orders',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveSupplierOrder,
		path: '/supplier-orders/create',
		title: 'header.navigation-titles.supplier-orders-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.supplier-orders",
				path : "/supplier-orders"
			},
		]
	},
	{
		component: SaveSupplierOrder,
		path: '/supplier-orders/update',
		title: 'header.navigation-titles.supplier-orders-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.supplier-orders",
				path : "/supplier-orders"
			},
		]
	},
	{
		component: InventoryCounts,
		path: '/inventory-counts',
		title: 'header.navigation-titles.inventory-counts',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveInventoryCount,
		path: '/inventory-counts/create',
		title: 'header.navigation-titles.inventory-counts-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.inventory-counts",
				path : "/inventory-counts"
			},
		]
	},
	{
		component: SaveInventoryCount,
		path: '/inventory-counts/update',
		title: 'header.navigation-titles.inventory-counts-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.inventory-counts",
				path : "/inventory-counts"
			},
		]
	},
	{
		component: InventoryCountsSummaryReport,
		path: '/reports/inventory-counts',
		title: 'header.navigation-titles.inventory-counts-summary',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.reports"
			}
		]
	},
	{
		component: Orders,
		path: '/orders',
		title: 'header.navigation-titles.orders',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: OrdersSummaryReport,
		path: '/reports/product-sales',
		title: 'header.navigation-titles.product-sales',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.reports"
			}
		]
	},
	{
		component: AccountingSummaryReport,
		path: '/reports/accounting-summary',
		title: 'header.navigation-titles.accounting-summary',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.reports"
			}
		]
	},
	{
		component: OrderSheets,
		path: '/order-sheets',
		title: 'header.navigation-titles.order-sheets',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.WAITER
		],
		home: [
			Roles.WAITER
		],
		hierarchy: [
		]
	},
	{
		component: Messages,
		path: '/messages',
		title: 'header.navigation-titles.messages',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
		]
	},
	{
		component: ManageOrderSheets,
		path: '/order-sheets/manage',
		title: 'header.navigation-titles.order-sheets-manage',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: AccountTransactions,
		path: '/account-transactions',
		title: 'header.navigation-titles.account-transactions',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: AccountStatements,
		path: '/account-statements',
		title: 'header.navigation-titles.account-statements',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: LedgerTransactions,
		path: '/ledger-transactions',
		title: 'header.navigation-titles.ledger-transactions',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: CustomTags,
		path: '/tags',
		title: 'header.navigation-titles.tags',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: Accounts,
		path: '/accounts',
		title: 'header.navigation-titles.accounts',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		permissions: [
			Permissions.ACCOUNT_CREATE
		],	
		hierarchy: [
		]
	},
	{
		component: SaveAccount,
		path: '/accounts/create',
		title: 'header.navigation-titles.accounts-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		permissions: [
			Permissions.ACCOUNT_CREATE
		],
		hierarchy: [
			/*{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.accounts",
				path : "/accounts"
			},*/
		]
	},
	{
		component: SaveAccount,
		path: '/accounts/update',
		title: 'header.navigation-titles.accounts-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			/*{{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.accounts",
				path : "/accounts"
			},*/
		]
	},
	{
		component: AccountActivities,
		path: '/account-activities',
		title:  'header.navigation-titles.account-activities',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			
		]
	},
	{
		component: MediaFiles,
		path: '/media-files',
		title:  'header.navigation-titles.media-files',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			
		]
	},
]